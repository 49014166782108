<template>
  <section class="cursos">
    <div class="busca">
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" placeholder="Buscar curso..." spellcheck="false" v-model="searchQuery" />
      </div>
      <div class="filtrar">
        <Svgs nome="setinha" />
        <select v-model="selectedFilter">
          <option value="todos">Todos</option>
          <option value="alfabetica-asc">Alfabética A-Z</option>
          <option value="alfabetica-desc">Alfabética Z-A</option>
          <option value="mais-antigos">Mais antigos</option>
          <option value="mais-novos">Mais novos</option>
        </select>
      </div>
    </div>
    <div v-for="curso in cursosFiltrados" :key="curso._id" class="curso">
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${curso.imagemCapa || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/background-vertical.svg'}')`"></div>
        </div>
      </div>
      <div class="nome">
        <h3>{{ curso.nome }}</h3>
        <p>{{ curso.descricao }}</p>
      </div>

      <div class="dados">
        <div class="dado">
          <Svgs nome="usuarios" />
          <p>
            <span>{{ curso.quantidadeAlunos }}</span>
          </p>
          <p>{{ textoAlunos(curso.quantidadeAlunos) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="play" />
          <p>
            <span>{{ curso.quantidadeModulos }}</span>
          </p>
          <p>{{ textoModulos(curso.quantidadeModulos) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="play" />
          <p>
            <span>{{ curso.quantidadeAulas }}</span>
          </p>
          <p>{{ textoAulas(curso.quantidadeAulas) }}</p>
        </div>
      </div>
      <div class="opcoes">
        <button class="excluir" @click="abrirModalEdicao('excluirCurso', 'excluir', 'Excluir curso', curso._id)">
          <Svgs nome="excluir" />
        </button>
        <button class="editar" @click="abrirModalEdicao('editarCurso', 'editar', 'Editar curso', curso._id)">
          <Svgs nome="editar" />
        </button>
        <button class="duplicar" @click="abrirModalEdicao('duplicarCurso', 'duplicar', 'Duplicar curso', curso._id)">
          <Svgs nome="duplicar" />
        </button>
        <button class="entrar" @click="irParaModulos(curso._id)">
          <p>Ver módulos</p>
          <Svgs nome="seta" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStoreModal, useStoreAreas, useStoreCursos } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModal = useStoreModal()
const router = useRouter()

const searchQuery = ref('')
const selectedFilter = ref('todos')

const cursosFiltrados = computed(() => {
  let cursos = storeCursos.cursos || []

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    cursos = cursos.filter((curso) => curso.nome.toLowerCase().includes(query) || curso.descricao.toLowerCase().includes(query))
  }

  switch (selectedFilter.value) {
    case 'alfabetica-asc':
      cursos = cursos.slice().sort((a, b) => a.nome.localeCompare(b.nome))
      break
    case 'alfabetica-desc':
      cursos = cursos.slice().sort((a, b) => b.nome.localeCompare(a.nome))
      break
    case 'mais-antigos':
      cursos = cursos.slice().sort((a, b) => new Date(a.dataRegistro) - new Date(b.dataRegistro))
      break
    case 'mais-novos':
      cursos = cursos.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
      break
  }

  return cursos
})

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

function irParaModulos(idCurso) {
  const idArea = storeAreas.area?._id
  storeCursos.receberPorId(idArea, idCurso)
  setTimeout(() => {
    router.push(`/areas/${storeAreas.area?.hotlink}/modulos`)
  }, 300)
}

const textoAlunos = (quantidade) => {
  return `${quantidade === 1 ? 'aluno' : 'alunos'}`
}

const textoModulos = (quantidade) => {
  return `${quantidade === 1 ? 'módulo' : 'módulos'}`
}

const textoAulas = (quantidade) => {
  return `${quantidade === 1 ? 'aula' : 'aulas'}`
}
</script>

<style scoped>
section.cursos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px 20px 20px;
  min-height: 100dvh;
}

.busca {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.busca .input {
  width: calc(80% - 10px);
}

.busca .filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(20% - 10px);
}

.busca .filtrar svg {
  position: absolute;
  right: 15px;
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
}

.curso {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-cinza-1);
  border-top: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px 0;
}

.curso:last-child {
  border-bottom: 1px solid var(--cor-cinza-3);
}

.curso .capa {
  width: 100%;
  max-width: 110px;
}

.curso .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.curso .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.curso .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.curso .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
}

.curso .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
  line-height: 1.5;
}

.curso .opcoes {
  display: flex;
  align-items: center;
}

.curso .opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: all 0.3s;
}

.curso .opcoes button.duplicar {
  margin: 0 10px;
}

.curso .opcoes button.editar {
  margin: 0 0 0 10px;
}

.curso .opcoes button.excluir {
  margin: 0;
}

.curso .opcoes button.entrar {
  justify-content: space-between;
  width: 140px;
  padding: 0 15px;
}

.curso .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.curso .opcoes button:hover {
  background-color: var(--cor-laranja);
}

.curso .opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.curso .dados {
  display: flex;
  align-items: center;
}

.curso .dados .dado {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
}

.curso .dados .dado:nth-child(1) {
  border-left: none;
}

.curso .dados .dado svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  margin: 0 5px 0 0;
}

.curso .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.curso .dados .dado p span {
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  section.cursos {
    flex-direction: column;
    width: 100%;
    padding: 0 20px 30px 20px;
  }

  .busca {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 20px 0;
  }

  .busca .input {
    width: calc(65% - 8px);
    margin: 0;
  }

  .busca .filtrar {
    width: calc(35% - 8px);
  }

  .curso {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }

  .curso .capa {
    width: 100%;
    max-width: 120px;
  }

  .curso .nome {
    margin: 0;
    width: calc(100% - 120px);
  }

  .curso .opcoes {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .curso .opcoes button {
    margin: 0;
  }

  .curso .opcoes button.duplicar {
    margin: 0 10px 0 0;
  }

  .curso .opcoes button.editar {
    margin: 0 10px;
  }

  .curso .dados {
    padding: 20px 0;
    width: 100%;
  }

  .curso .dados .dado svg {
    width: 15px;
    min-width: 15px;
    max-height: 20px;
    margin: 0 5px 0 0;
  }

  .curso .dados .dado p {
    font-size: var(--f0);
    line-height: 1.3;
  }

  .curso .dados .dado p span {
    font-size: var(--f1);
    margin: 0 3px 0 0;
  }
}
</style>
