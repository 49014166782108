<template>
  <section class="criar">
    <div class="quadro">
      <div class="tela">
        <div class="icone">
          <Svgs nome="cursor" />
        </div>
        <p>Suas áreas</p>
      </div>

      <div class="plano">
        <img :src="`https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/planos/${storePerfil.usuario?.assinatura?.plano || 'free'}-ico.svg`" />
        <div class="info">
          <div class="progresso">
            <div class="atual" :style="{ width: `${percentualProgresso || 100}%` }"></div>
          </div>
          <p>
            {{ storeAreas.areas?.length }} de {{ limitePlano }} {{ textoLimitePlano }}
            <span>{{ percentualProgressoTexto }}%</span>
          </p>
        </div>
        <div class="info">
          <div class="progresso">
            <div class="atual" :style="{ width: `${percentualProgressoAlunos || 100}%` }"></div>
          </div>
          <p>
            {{ storePainel.estatisticas?.quantidadeAlunos }} de {{ limiteAlunosFormatado }} {{ textoLimiteAlunos }}
            <span>{{ percentualProgressoAlunosTexto }}%</span>
          </p>
        </div>
      </div>

      <div class="dados">
        <div class="tag on">
          <Svgs nome="usuarios" />
          <p>{{ storePainel.estatisticas?.quantidadeAlunos }} alunos no total</p>
        </div>
      </div>

      <button @click="abrirModal('criarArea', 'mais', 'Criar área')">
        <Svgs nome="mais" />
        <p>Criar área agora</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStorePerfil, useStoreModal, useStoreAreas, useStorePainel } from '@stores'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storeModal = useStoreModal()
const storeAreas = useStoreAreas()
const storePainel = useStorePainel()

function abrirModal(nome, icone, titulo) {
  storeModal.abrirModal(nome, icone, titulo)
}

const limitePlano = computed(() => {
  const tipoPlano = storePerfil.usuario?.assinatura?.plano
  switch (tipoPlano) {
    case 'free':
      return 1
    case 'essencial':
      return 1
    case 'pro':
      return 3
    case 'expert':
      return 15
    default:
      return 0
  }
})

const limiteAlunos = computed(() => {
  const tipoPlano = storePerfil.usuario?.assinatura?.plano
  switch (tipoPlano) {
    case 'free':
      return 50
    case 'essencial':
      return 4000
    case 'pro':
      return 15000
    case 'expert':
      return 100000
    default:
      return 0
  }
})

const limiteAlunosFormatado = computed(() => {
  return limiteAlunos.value.toLocaleString('pt-BR')
})

const formatarQuantidade = (quantidade, singular, plural) => {
  return quantidade === 1 ? singular : plural
}

const textoLimitePlano = computed(() => {
  const areas = storeAreas.areas || []
  const quantidade = areas.length
  return formatarQuantidade(quantidade, 'área', 'áreas')
})

const textoLimiteAlunos = computed(() => {
  const quantidade = storePainel.estatisticas?.quantidadeAlunos || 0
  return formatarQuantidade(quantidade, 'aluno', 'alunos')
})

const percentualProgressoAlunosTexto = computed(() => {
  const quantidadeCriada = storePainel.estatisticas?.quantidadeAlunos || 0
  const limite = limiteAlunos.value
  return limite > 0 ? Math.min(Math.round((quantidadeCriada / limite) * 100), 100) : 0
})

const percentualProgressoAlunos = computed(() => {
  const quantidadeCriada = storePainel.estatisticas?.quantidadeAlunos || 0
  const limite = limiteAlunos.value
  return limite > 0 ? Math.min((quantidadeCriada / limite) * 100, 100) : 0
})

const percentualProgressoTexto = computed(() => {
  const quantidadeCriada = storeAreas.areas?.length || 0
  const limite = limitePlano.value
  return limite > 0 ? Math.min(Math.round((quantidadeCriada / limite) * 100), 100) : 0
})

const percentualProgresso = computed(() => {
  const quantidadeCriada = storeAreas.areas?.length || 0
  const limite = limitePlano.value
  return limite > 0 ? Math.min((quantidadeCriada / limite) * 100, 100) : 0
})

function receberDados() {
  storePainel.receberEstatisticas()
}

onMounted(() => {
  receberDados()
})
</script>

<style scoped>
section.criar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
}

.quadro .tela {
  display: flex;
  align-items: center;
  margin: 0 30px 0 0;
}

.quadro .tela .icone {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
}

.quadro .tela .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}
.quadro .tela p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .plano {
  display: flex;
  align-items: center;
}

.quadro .plano img {
  width: 100%;
  max-width: 30px;
  max-height: 30px;
}

.quadro .plano .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  margin: 0 0 0 10px;
  background-color: var(--cor-cinza-1);
  border-radius: 5px;
  padding: 10px;
}

.quadro .plano .info .progresso {
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
}

.quadro .plano .info .progresso .atual {
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-branco);
  transition: all 3s;
}

.quadro .plano .info p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
  margin: 10px 0 0 0;
}

.quadro .plano .info p span {
  color: var(--cor-cinza-5);
}

.quadro .dados {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.quadro .dados .tag {
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
}

.quadro .dados .tag p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.quadro .dados .tag svg {
  width: 15px;
  min-width: 15px;
  margin: 0 10px 0 0;
}

.quadro .dados .tag.on svg {
  fill: var(--cor-laranja);
}

.quadro .dados .tag.off svg {
  fill: var(--cor-vermelho);
}

.quadro .dados .tag.www svg {
  fill: var(--cor-roxo);
}

.quadro button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
  border-radius: 10px;
  background-color: var(--cor-laranja);
  transition: all 0.3s;
}

.quadro button:hover {
  background-color: var(--cor-laranja-escuro);
}

.quadro button svg {
  width: 15px;
  min-width: 15px;
  margin: 0 10px 0 0;
  fill: var(--cor-branco-fixo);
}

.quadro button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.criar {
    padding: 20px 20px 0 20px;
  }

  .quadro {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
  }

  .quadro .tela {
    width: 50%;
    margin: 0;
  }

  .quadro .tela .icone {
    width: 30px;
    height: 30px;
    margin: 0 8px 0 0;
  }

  .quadro .tela .icone svg {
    width: 15px;
    min-width: 15px;
  }
  .quadro .tela p {
    font-size: var(--f2);
  }

  .quadro .plano {
    justify-content: flex-start;
    width: 100%;
    margin: 15px 0 0 0;
  }

  .quadro .plano img {
    width: 20px;
    min-width: 20px;
    max-height: 20px;
  }

  .quadro .plano .info {
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 0 0 10px;
    width: auto;
  }

  .quadro .plano .info .progresso {
    display: none;
    width: 80px;
    min-width: 80px;
  }

  .quadro .plano .info p {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
  }

  .quadro .plano .info p span {
    margin: 0 0 0 5px;
  }

  .quadro .dados {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0;
    padding: 20px 0;
    width: 100%;
  }

  .quadro .dados .tag {
    margin: 0;
  }

  .quadro .dados .tag p {
    font-size: var(--f0);
  }

  .quadro .dados .tag svg {
    width: 11px;
    min-width: 11px;
    margin: 0 8px 0 0;
  }

  .quadro button {
    padding: 12px 30px;
    width: 100%;
  }

  .quadro button svg {
    width: 13px;
    min-width: 13px;
    margin: 0 8px 0 0;
  }
}
</style>
