import { sanitize } from './sanitize.js'

function limparAcentos(content) {
  return content
    ?.toLowerCase()
    .replace(/[À|Á|Â|Ä|Ã]/gi, 'A')
    .replace(/[È|É|Ê|Ë|Ẽ]/gi, 'E')
    .replace(/[Ì|Í|Î|Ï|Ĩ]/gi, 'I')
    .replace(/[Ò|Ó|Ô|Ö|Õ]/gi, 'O')
    .replace(/[Ù|Ú|Û|Ü|Ũ]/gi, 'U')
    .replace(/[Ç]/gi, 'C')
    .replace(/[Ñ]/gi, 'N')
    .replace(/[\n]/gi, ' ')
}
function converterEmHotlink(content) {
  return content
    ?.toUpperCase()
    .replace(/[À|Á|Â|Ä|Ã]/gi, 'A')
    .replace(/[È|É|Ê|Ë|Ẽ]/gi, 'E')
    .replace(/[Ì|Í|Î|Ï|Ĩ]/gi, 'I')
    .replace(/[Ò|Ó|Ô|Ö|Õ]/gi, 'O')
    .replace(/[Ù|Ú|Û|Ü|Ũ]/gi, 'U')
    .replace(/[Ç]/gi, 'C')
    .replace(/[Ñ]/gi, 'N')
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '')
}

function limparState(state) {
  for (const index in state) {
    const valor = state[index]
    if (Array.isArray(valor)) state[index] = []
    else if (typeof valor === 'object') limparState(valor)
    else if (typeof valor === 'boolean') state[index] = false
    else if (typeof valor === 'number') state[index] = 0
    else state[index] = ''
  }
}

function reconhecerQuebraLinha(texto) {
  return texto?.replace(/\r?\n/g, '<br>')
}

function substituirUnderline(string) {
  if (!string) return
  return string?.replace(/_/gi, '-')
}

function substituirHifen(string) {
  if (!string) return
  return string?.replace(/-/gi, '_')
}

function nomeSobrenome(nome) {
  if (!nome) return

  let nomeCompleto = nome?.split(' ')
  let primeiroNome = nomeCompleto[0]
  let ultimoNome = nomeCompleto.length > 1 ? nomeCompleto[nomeCompleto.length - 1] : ''

  // CAPITALIZAR STRINGS
  primeiroNome = primeiroNome.toLowerCase().replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
  ultimoNome = ultimoNome.toLowerCase().replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))

  return `${primeiroNome} ${ultimoNome}`
}

function primeiroNome(nome) {
  if (!nome) return
  const primeiroNome = nome?.split(' ')[0]

  return primeiroNome
}

function formatarTextoGrafico(numeroRegistros = 0, contextoSingular, contextoPlural) {
  if (numeroRegistros === 0) return `Não há ${contextoPlural}`
  if (numeroRegistros === 1) return `1 ${contextoSingular}`
  if (numeroRegistros > 1) return `${numeroRegistros} ${contextoPlural}`
}

function limitarCaracteres(texto, quantidade = 100) {
  const reticencias = texto?.length < quantidade ? '' : '...'
  return `${texto?.substring(0, quantidade)}${reticencias}`
}

function identificarTarget(url) {
  if (url?.toString()?.[0] === '#') return '_self'
  else return '_blank'
}

function converterTextoEmFormatoHtml(texto, corSpan, backgroundSpan) {
  if (!texto) return

  texto = sanitize(texto)

  return texto
    .replace(/\r?\n/g, '<br>') // Substitui quebras de linha por <br>
    .replace(/\*\*(.+?)\*\*/g, '<b>$1</b>') // Substitui **texto** por <b>texto</b>
    .replace(/_(.+?)_/g, '<i>$1</i>') // Substitui _texto_ por <i>texto</i>
    .replace(/\*(.+?)\*/g, `<span style="color: #${corSpan}; background-color: #${backgroundSpan}">$1</span>`) // Substitui *texto* por <span> com estilos
}

function converterFormatoHtmlEmTexto(texto) {
  let textoFormatado = texto?.replace(/<span>/gi, '*')?.replace(/<\/span>/gi, '*')
  return sanitize(textoFormatado)
}

function extrairNomeIconeDeUrl(url) {
  return url?.match(/\/([^/]+\/[^/]+)\.svg$/)?.[1]
}

function converterNomeIconeParaUrl(nomeIcone) {
  return `https://central-statics.nyc3.digitaloceanspaces.com/unicpages/banco/icones/${nomeIcone}.svg`
}

function buscarPorTags(texto, listaObjetos) {
  texto = limparAcentos(texto)
  const palavrasChaveDigitadas = texto?.split(' ')
  const objEncontrado = listaObjetos?.filter((objeto) => palavrasChaveDigitadas?.every((palavra) => objeto?.tags?.some((tag) => limparAcentos(tag)?.includes(palavra))))
  return objEncontrado
}

function verificarSeExisteVariavelNoObjeto(obj, variavel) {
  return Object.prototype.hasOwnProperty.call(obj, variavel)
}
function limitarTexto(texto, maxLength, sufixo = '...') {
  if (!texto || typeof texto !== 'string') {
    return ''
  }

  if (texto.length > maxLength) {
    return texto.slice(0, maxLength) + sufixo
  }

  return texto
}

export default {
  limparAcentos,
  limparState,
  substituirUnderline,
  substituirHifen,
  reconhecerQuebraLinha,
  nomeSobrenome,
  primeiroNome,
  formatarTextoGrafico,
  limitarCaracteres,
  identificarTarget,
  converterTextoEmFormatoHtml,
  converterFormatoHtmlEmTexto,
  extrairNomeIconeDeUrl,
  converterNomeIconeParaUrl,
  buscarPorTags,
  verificarSeExisteVariavelNoObjeto,
  converterEmHotlink,
  limitarTexto
}
