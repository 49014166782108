<template>
  <section class="perfil" :style="conteudoEstilo">
    <div class="conteudo">
      <div class="topo">
        <div class="capa" :style="`background-image: url('${tela.imagemCapa || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/background-vertical.svg'}')`">
          <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
        </div>
        <div class="nome">
          <h3 :style="corNome">Nome do Aluno</h3>
          <p :style="corEmail">email@email.com</p>
        </div>
      </div>

      <div class="opcoes">
        <button :style="botoesEstilo">
          <Svgs :style="svgBotoesEstilo" nome="editar" />
          <div class="nome">
            <h3 :style="textoBotoesEstilo">Editar Perfil</h3>
            <p :style="textoBotoesEstilo">
              Altere seus dados
              <span>pessoais na área</span>
            </p>
          </div>
        </button>
        <button :style="botoesEstilo">
          <Svgs :style="svgBotoesEstilo" nome="cadeado" />
          <div class="nome">
            <h3 :style="textoBotoesEstilo">Alterar Senha</h3>
            <p :style="textoBotoesEstilo">
              Altere sua senha
              <span>na área</span>
            </p>
          </div>
        </button>
        <button :style="botoesEstilo">
          <Svgs :style="svgBotoesEstilo" nome="sair" />
          <div class="nome">
            <h3 :style="textoBotoesEstilo">Sair Agora</h3>
            <p :style="textoBotoesEstilo">
              Deslogue de
              <span>sua conta</span>
            </p>
          </div>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStorePersonalizacao, useStorePerfil } from '@stores'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storePersonalizacao = useStorePersonalizacao()

const tela = computed(() => storePersonalizacao.personalizacao?.telaPerfil || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#E1E3EB'
}))

const corNome = computed(() => ({
  color: tela.value.corNome || '#262836',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const corEmail = computed(() => ({
  color: tela.value.corEmail || '#262836',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botoesEstilo = computed(() => ({
  backgroundColor: tela.value.corBotoesFundo || '#BBC1D1'
}))

const textoBotoesEstilo = computed(() => ({
  color: tela.value.corBotoesTexto || '#262836',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const svgBotoesEstilo = computed(() => ({
  fill: tela.value.corBotoesTexto || '#262836'
}))

function atualizarFonte(fonte) {
  if (!fonte) return

  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

function renderizarFonte() {
  const fonte = storePersonalizacao.personalizacao?.telaPerfil?.fonte
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte || 'Figtree',
    fontWeight: estilo || '400'
  }
}

watch(
  () => storePersonalizacao.personalizacao?.telaPerfil?.fonte,
  (novaFonte, fonteAntiga) => {
    if (novaFonte !== fonteAntiga) {
      atualizarFonte(novaFonte)
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.perfil {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 0 300px;
  background-color: #ffffff;
  min-height: 100dvh;
  position: relative;
}

.conteudo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.topo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.topo .capa {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: #000;
  position: relative;
}

.topo .capa .foto {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  bottom: -75px;
}

.topo .nome {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding: 100px 0 0 0;
}

.topo .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #262836;
}

.topo .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  margin: 5px 0 0 0;
}

.opcoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 30px 0 0 0;
}

.opcoes button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 400px;
  background-color: #bbc1d1;
  padding: 20px;
  border-radius: 10px;
  margin: 0 0 10px 0;
  transition: all 0.3s;
}

.opcoes button:hover {
  opacity: 0.6;
}

.opcoes button svg {
  width: 30px;
  min-width: 30px;
  max-height: 30px;
  fill: #262836;
}

.opcoes button .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 10px;
}

.opcoes button .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
}

.opcoes button .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #262836;
  margin: 5px 0 0 0;
}

@media screen and (max-width: 1000px) {
  section.perfil {
    padding: 0;
  }

  .menu {
    display: none;
  }

  .topo .capa {
    height: 150px;
  }

  .topo .capa .foto {
    width: 100px;
    height: 100px;
    bottom: -50px;
  }

  .topo .nome {
    padding: 70px 0 0 0;
  }

  .opcoes {
    padding: 0 30px;
  }

  .opcoes button svg {
    width: 25px;
    min-width: 25px;
    max-height: 25px;
  }
}

/* Mobile no Produtor */

.mobile section.perfil {
  padding: 0;
}

.mobile .menu {
  display: none;
}

.mobile .topo .capa {
  height: 150px;
}

.mobile .topo .capa .foto {
  width: 100px;
  height: 100px;
  bottom: -50px;
}

.mobile .topo .nome {
  padding: 70px 0 0 0;
}

.mobile .opcoes {
  padding: 0 30px;
}

.mobile .opcoes button svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
}
</style>
