<template>
  <Modal nome="verNotificacao">
    <div class="notificacao">
      <div class="foto" style="background-image: url('https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png')"></div>
      <h3>{{ state.titulo }}</h3>
      <p>{{ state.texto }}</p>
    </div>

    <div class="dados">
      <div class="linha">
        <h3>Área</h3>
        <Svgs nome="cursor" />
        <p>{{ state.nomeArea }}</p>
      </div>
      <div class="linha">
        <h3>Curso</h3>
        <Svgs nome="curso" />
        <p>{{ state.nomeCurso }}</p>
      </div>
      <div class="linha">
        <h3>Data</h3>
        <Svgs nome="relogio" />
        <p>{{ formatarData(state.registro) }}</p>
      </div>
      <div class="linha">
        <h3>Status</h3>
        <Svgs :nome="`${state.visto ? 'check' : 'sino'}`" />
        <p>{{ state.visto ? 'Visto' : 'Não visto' }}</p>
      </div>
    </div>

    <SalvarModal nome="Ir para o comentário" @click="verComentario()" />
  </Modal>
</template>

<script setup>
import { reactive, onMounted, watch, inject, ref, computed } from 'vue'
import { useStoreAreas, useStoreModal, useStoreNotificacoes, useStoreCursos, useStoreModulos, useStoreAulas } from '@stores'
import { useRouter } from 'vue-router'
import Modal from '@components/global/modal/Modal.vue'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const router = useRouter()
const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeAulas = useStoreAulas()
const storeModal = useStoreModal()
const storeNotificacoes = useStoreNotificacoes()
const dadosModal = computed(() => storeModal.dadosDoModal('verNotificacao'))

const areaMap = ref(new Map())

const state = reactive({
  idArea: '',
  nomeArea: '',
  idCurso: '',
  nomeCurso: '',
  titulo: '',
  texto: '',
  tipo: '',
  link: '',
  visto: '',
  registro: ''
})

async function verComentario() {
  const idArea = storeNotificacoes.notificacao?.area?.id
  const idCurso = storeNotificacoes.notificacao?.curso?.id
  const idModulo = storeNotificacoes.notificacao?.idModulo
  const idAula = storeNotificacoes.notificacao?.idAula

  await storeAreas.receberPorId(idArea)

  await storeCursos.receberPorId(idArea, idCurso)

  await storeModulos.receberPorId(idArea, idCurso, idModulo)

  await storeAulas.receberPorId(idArea, idCurso, idModulo, idAula)

  if (idArea && idCurso && idModulo && idAula) {
    storeModal.fecharModal('verNotificacao')
    router.push(`/areas/${storeAreas.area?.hotlink}/aulas/visualizar`)
  }
}

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()

    return `${day}/${month}/${year}`
  }
}

function limparCampos() {
  state.idArea = ''
  state.nomeArea = ''
  state.idCurso = ''
  state.nomeCurso = ''
  state.titulo = ''
  state.texto = ''
  state.tipo = ''
  state.link = ''
  state.visto = ''
  state.registro = ''
}

function preencherDados() {
  state.idArea = storeNotificacoes.notificacao?.area?.id
  state.nomeArea = storeNotificacoes.notificacao?.area?.nome
  state.idCurso = storeNotificacoes.notificacao?.curso?.id
  state.nomeCurso = storeNotificacoes.notificacao?.curso?.nome
  state.titulo = storeNotificacoes.notificacao?.titulo
  state.texto = storeNotificacoes.notificacao?.texto
  state.tipo = storeNotificacoes.notificacao?.tipo
  state.link = storeNotificacoes.notificacao?.link
  state.visto = storeNotificacoes.notificacao?.visto
  state.registro = storeNotificacoes.notificacao?.dataRegistro
}

async function pegarIdNotificacao() {
  if (dadosModal.value.id) {
    await storeNotificacoes.receberPorId(dadosModal.value.id)
    preencherDados()
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIdNotificacao()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})

onMounted(async () => {
  await storeAreas.receberTodos()
  storeAreas.areas.forEach((area) => {
    areaMap.value.set(area._id, area.imagemLogo)
  })
})
</script>

<style scoped>
.notificacao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.notificacao .foto {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  margin: 0 0 20px 0;
}

.notificacao h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.notificacao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 10px 0 0 0;
}

.dados {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--cor-cinza-3);
  margin: 20px 0 0 0;
}

.dados h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 10px 0 20px 0;
}

.linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid var(--cor-cinza-3);
  position: relative;
}

.linha h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.linha p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-laranja);
  margin: 0 0 0 10px;
}
.linha svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-laranja);
  margin-left: auto;
}
button.salvar {
  margin: 20px 0 0 0;
}
</style>
