<template>
  <section class="aula">
    <!-- Quadro -->
    <div class="quadro">
      <div class="texto">
        <button class="posicao" @click="router.push(`/areas/${storeAreas.area?.hotlink}/aulas`)">
          <Svgs nome="seta" />
          <p>Voltar</p>
        </button>
        <div class="info">
          <h2>{{ storeAulas.aula?.nome }}</h2>
          <p>{{ storeAulas.aula?.descricao }}</p>
        </div>
      </div>
      <!-- Vídeo -->
      <div class="video">
        <div class="tamanho">
          <iframe :src="storeAulas.aula?.urlVideo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
        </div>
      </div>
      <!-- Links -->
      <div class="links" v-if="storeAulas.aula?.links?.length >= 1">
        <h3>Links da Aula</h3>
        <a v-for="(link, index) in storeAulas.aula?.links" :key="`link-${index}`" :href="link.url" target="_blank">
          <Svgs nome="link" />
          <p>{{ link.nome }}</p>
        </a>
      </div>
      <!-- Arquivos -->
      <div class="arquivos" v-if="storeAulas.aula?.arquivos?.length >= 1">
        <h3>Arquivos da Aula</h3>
        <a v-for="(arquivo, index) in storeAulas.aula?.arquivos" :key="`arquivo-${index}`" :href="arquivo.url" target="_blank">
          <Svgs nome="arquivo" />
          <p>{{ arquivo.nome }}, {{ arquivo.peso }}</p>
        </a>
      </div>
    </div>
    <!-- Comentários -->
    <div class="comentarios">
      <div class="comentar">
        <div class="input">
          <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
          <textarea v-model="state.textoComentario" rows="1" spellcheck="false" placeholder="Adicione um comentário" v-redimensionar-textarea @focus="mostrarBotoes" @blur="ocultarBotoes"></textarea>
        </div>
        <div class="botoes" v-if="state.mostrarBotoes">
          <button class="cancelar" @click="cancelarComentario">Cancelar</button>
          <button class="enviar" @click="comentar">Comentar</button>
        </div>
      </div>

      <div class="comentario" v-for="comentario in storeComentarios.comentarios" :id="comentario.idComentarioPrincipal" :key="comentario._id">
        <div class="foto" :style="`background-image: url('${comentario.autor?.aluno?.imagemPerfil || comentario.autor?.produtor?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
        <div class="nome">
          <h4 v-if="comentario.autor?.produtor">ADM</h4>
          <h3>{{ comentario.autor?.aluno?.nome || comentario.autor?.produtor?.nome }}</h3>
          <p>{{ formatarData(comentario.dataRegistro) }}</p>
        </div>
        <div class="texto">
          <p>{{ comentario.texto }}</p>
        </div>

        <button class="responder" @click="mostrarRespondendo(comentario._id)" v-if="!state.mostrarBotoesResposta">Responder</button>

        <div class="respondendo" v-if="state.comentarioRespondendo === comentario._id">
          <div class="input">
            <textarea v-model="state.textoResposta" rows="1" spellcheck="false" placeholder="Adicione um comentário" v-redimensionar-textarea></textarea>
          </div>
          <div class="botoes">
            <button class="cancelar" @click="cancelarResposta">Cancelar</button>
            <button class="enviar" @click="responder(comentario._id)">Comentar</button>
          </div>
        </div>

        <div class="resposta" v-for="resposta in comentario.respostas" :id="resposta._id" :key="resposta._id">
          <div class="foto" :style="`background-image: url('${resposta.autor?.aluno?.imagemPerfil || resposta.autor?.produtor?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
          <div class="nome">
            <h4 v-if="resposta.autor?.produtor">ADM</h4>
            <h3>{{ resposta.autor?.aluno?.nome || resposta.autor?.produtor?.nome }}</h3>
            <p>{{ formatarData(resposta.dataRegistro) }}</p>
          </div>
          <div class="texto">
            <p>{{ resposta.texto }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, watch, onMounted, inject } from 'vue'
import { useStoreAulas, useStoreAreas, useStoreCursos, useStoreModulos, useStoreComentarios, useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeAulas = useStoreAulas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeComentarios = useStoreComentarios()
const storePerfil = useStorePerfil()
const router = useRouter()
const emitter = inject('emitter')

const state = reactive({
  textoComentario: '',
  textoResposta: '',
  loading: false,
  mostrarBotoes: false,
  mostrarBotoesResposta: false,
  comentarioRespondendo: null
})

const mostrarBotoes = () => {
  state.mostrarBotoes = true
}

const ocultarBotoes = () => {
  if (state.textoComentario === '') {
    state.mostrarBotoes = false
  }
}

const cancelarComentario = () => {
  state.textoComentario = ''
  state.mostrarBotoes = false
}

const mostrarRespondendo = (idComentario) => {
  state.comentarioRespondendo = idComentario
  state.mostrarBotoesResposta = true
}

const cancelarResposta = () => {
  state.textoResposta = ''
  state.mostrarBotoesResposta = false
  state.comentarioRespondendo = null
}

async function comentar() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  const payload = {
    texto: state.textoComentario,
    link: 'https://mber.live'
  }

  if (idArea && idCurso && idModulo && idAula) {
    const comentadoOk = await storeComentarios.comentar(idArea, idCurso, idModulo, idAula, payload)
    if (comentadoOk !== true) return

    state.textoComentario = ''
    state.mostrarBotoes = false
    emitter.emit('atualizarComentarios')
  }
}

async function responder(idComentario) {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  const payload = {
    texto: state.textoResposta,
    link: 'https://mber.live'
  }

  if (idArea && idCurso && idModulo && idAula && idComentario) {
    const respondidoOk = await storeComentarios.responder(idArea, idCurso, idModulo, idAula, idComentario, payload)
    if (respondidoOk !== true) return

    state.textoResposta = ''
    state.mostrarBotoesResposta = false
    state.comentarioRespondendo = null
    emitter.emit('atualizarComentarios')
  }
}

async function carregarComentarios() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  if (idArea && idCurso && idModulo && idAula) {
    const carregadoOk = await storeComentarios.receberTodos(idArea, idCurso, idModulo, idAula)
    if (carregadoOk !== true) return
  }
}

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há menos de um minuto'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}

onMounted(() => {
  emitter.on('atualizarComentarios', carregarComentarios)
})

watch(
  () => [storeAreas.area?._id, storeCursos.curso?._id, storeModulos.modulo?._id, storeAulas.aula?._id],
  ([idArea, idCurso, idModulo, idAula]) => {
    if (idArea && idCurso && idModulo && idAula) {
      carregarComentarios()
    }
  },
  { immediate: true, deep: true }
)
</script>

<style scoped>
section.aula {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px 20px 20px;
  background-color: var(--cor-cinza-1);
  z-index: 9;
  transition: all 0.3s;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  position: relative;
}

.quadro .video {
  width: 100%;
}

.quadro .video .tamanho {
  display: flex;
  position: relative;
  padding-bottom: 56.52%;
}

.quadro .video .tamanho iframe {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.quadro .texto {
  display: flex;
  width: 100%;
  padding: 20px;
}

.quadro .texto button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
  margin: 0 15px 0 0;
  transition: all 0.3s;
}

.quadro .texto button:hover {
  background-color: var(--cor-cinza-4);
}

.quadro .texto button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transform: rotate(180deg);
  margin: 0 10px 0 0;
}

.quadro .texto h2 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
}

.quadro .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.quadro .links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
}

.quadro .links h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  width: 100%;
}

.quadro .links a {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul);
  margin: 5px 15px 0 0;
  transition: all 0.3s;
}

.quadro .links a:hover {
  color: var(--cor-verde);
}

.quadro .links a:hover svg {
  fill: var(--cor-verde);
}

.quadro .links a svg {
  width: 12px;
  min-width: 12px;
  margin: 0 7px 0 0;
  fill: var(--cor-azul);
  transition: all 0.3s;
}

.quadro .arquivos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.quadro .arquivos h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  width: 100%;
}

.quadro .arquivos a {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul);
  margin: 5px 15px 0 0;
  transition: all 0.3s;
}

.quadro .arquivos a:hover {
  color: var(--cor-verde);
}

.quadro .arquivos a:hover svg {
  fill: var(--cor-verde);
}

.quadro .arquivos a svg {
  width: 12px;
  min-width: 12px;
  margin: 0 7px 0 0;
  fill: var(--cor-azul);
  transition: all 0.3s;
}

.comentarios {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;
  padding: 0 0 0 20px;
}

.comentarios .comentar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.comentarios .input {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.comentarios .input textarea {
  padding: 15px;
  font-family: var(--regular);
  font-size: var(--f2);
  border-radius: 10px;
  width: 100%;
  background-color: var(--cor-cinza-2);
  color: var(--cor-branco);
  resize: none;
  border: none;
  transition: all 0.3s;
}

.comentarios .input textarea:focus {
  background-color: var(--cor-cinza-3);
}

.comentarios .input textarea::placeholder {
  color: var(--cor-cinza-5);
}

.comentarios .input .foto {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 5px 15px 0 0;
}

.comentarios .botoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0 0 0;
  animation: fadeIn 0.3s linear;
}

.comentarios .botoes button.enviar {
  font-size: var(--f2);
  font-family: var(--bold);
  padding: 12px 20px;
  border-radius: 50px;
  color: var(--cor-branco);
  background-color: var(--cor-laranja);
  transition: all 0.3s;
}

.comentarios .botoes button.enviar:hover {
  background-color: var(--cor-laranja-escuro);
}

.comentarios .botoes button.cancelar {
  font-size: var(--f2);
  font-family: var(--regular);
  color: var(--cor-cinza-5);
  background-color: transparent;
  padding: 10px;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.comentarios .botoes button.cancelar:hover {
  color: var(--cor-branco);
}

.comentarios .respondendo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px 0 0 0;
  animation: fadeIn 0.3s linear;
}

.comentarios .respondendo .input {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 0 0;
}

.comentarios .comentario {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 0 55px;
  margin: 30px 0 0 0;
  position: relative;
}

.comentarios .comentario .foto {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}

.comentarios .comentario .nome {
  display: flex;
  align-items: center;
}

.comentarios .comentario .nome h4 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

.comentarios .comentario .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

.comentarios .comentario .nome.produtor h3 {
  color: var(--cor-verde);
}

.comentarios .comentario .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.comentarios .comentario .texto {
  display: flex;
  padding: 5px 0 0 0;
}

.comentarios .comentario .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  word-break: break-all;
  line-height: 1.5;
}

.comentarios .comentario button.responder {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: var(--cor-cinza-3);
  padding: 8px 15px;
  border-radius: 50px;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}

.comentarios .comentario button.responder:hover {
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
}

.comentarios .resposta {
  position: relative;
  padding: 0 0 0 45px;
  margin: 30px 0 0 0;
}

.comentarios .resposta .foto {
  width: 35px;
  height: 35px;
  right: -50px;
}

.comentarios .resposta .nome h3 {
  font-size: var(--f1);
}

.comentarios .resposta .nome h4 {
  font-size: var(--f1);
}

.comentarios .resposta .texto p {
  font-size: var(--f1);
  word-break: break-all;
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.aula {
    flex-direction: column;
  }

  .quadro {
    width: 100%;
  }

  .quadro .texto {
    padding: 15px;
  }

  .quadro .texto button {
    padding: 10px 15px;
    margin: 0 10px 0 0;
  }

  .quadro .texto button p {
    display: none;
  }

  .quadro .texto button svg {
    width: 15px;
    min-width: 15px;
    margin: 0;
  }

  .quadro .texto h2 {
    font-size: var(--f2);
  }

  .quadro .texto p {
    font-size: var(--f2);
  }

  .comentarios {
    width: 100%;
    padding: 20px 0 0 0;
  }
}
</style>
