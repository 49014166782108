<template>
  <Modal nome="migrarAula">
    <div class="conteudo">
      <div class="item">
        <div class="info">
          <div class="nome">
            <h3>{{ storeAulas.aula?.nome }}</h3>
            <p>Uma cópia da aula será criada no módulo que selecionar abaixo!</p>
          </div>
          <div class="status on">
            <span></span>
            <h3>{{ storeAulas.aula?.duracao }} minutos</h3>
          </div>
        </div>
      </div>

      <label>
        Selecione um curso
        <span>*</span>
      </label>
      <div class="select">
        <Svgs nome="setinha" />
        <select v-model="state.idCurso">
          <option v-for="(curso, index) in storeCursos.cursos" :key="index" :value="curso._id">Curso {{ curso.nome }}</option>
        </select>
      </div>

      <label v-if="state.idCurso && state.modulos.length">
        Copiar aula para qual módulo?
        <span>*</span>
      </label>
      <div class="select" v-if="state.idCurso && state.modulos.length">
        <Svgs nome="setinha" />
        <select v-model="state.idModulo">
          <option v-for="(modulo, index) in state.modulos" :key="index" :value="modulo._id">Módulo {{ modulo.nome }}</option>
        </select>
      </div>

      <BotaoSalvar nome="Migrar aula" @click="migrarAula()" />
    </div>
  </Modal>
</template>
<script setup>
import { inject, computed, watch, onMounted, reactive } from 'vue'
import { useStoreAreas, useStoreModal, useStoreModulos, useStoreCursos, useStoreAulas } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAulas = useStoreAulas()
const storeModulos = useStoreModulos()
const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('migrarAula'))

const state = reactive({
  idCurso: '',
  idModulo: '',
  modulos: []
})

async function pegarId() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  if (dadosModal.value.id) {
    await storeAulas.receberPorId(idArea, idCurso, idModulo, dadosModal.value.id)
  }
}

async function carregarModulos() {
  if (!state.idCurso) {
    state.modulos = []
    return
  }

  await storeModulos.receberTodos(storeAreas.area?._id, state.idCurso)

  state.modulos = [...storeModulos.modulos]
}

async function migrarAula() {
  const idArea = storeAreas.area?._id
  const idCurso = state.idCurso
  const idModulo = state.idModulo

  const payload = storeAulas.aula

  const adicionadoOk = await storeAulas.adicionar(idArea, idCurso, idModulo, payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarAulas')
  storeModal.fecharModal('migrarAula')
  limparCampos()
}

function limparCampos() {
  storeAulas.aula = []
  state.idCurso = ''
  state.idModulo = ''
}

watch(
  () => state.idCurso,
  () => carregarModulos()
)

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarId()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.inputUrl {
  margin: 0 0 20px 0;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
}

.item .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.item .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item .status {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  padding: 10px 15px;
  border-radius: 50px;
  min-width: 100px;
}

.item .status span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.item .status h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item .status.on span {
  background-color: var(--cor-azul);
}

.item .status.off span {
  background-color: var(--cor-vermelho);
}

.item .links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--cor-cinza-3);
  border-bottom: 1px solid var(--cor-cinza-3);
}

.item .links a.dominio {
  border-right: 2px solid var(--cor-cinza-3);
}

.item .links a {
  text-align: center;
  width: 50%;
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  padding: 15px 5px;
  transition: all 0.3s;
}

.item .links a.sem {
  opacity: 0.5;
  pointer-events: none;
}

.item .links a:hover {
  color: var(--cor-azul);
}

button.salvar {
  margin: 20px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .item {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .item .info {
    width: 100%;
    padding: 20px;
  }

  .item .nome h3 {
    font-size: var(--f1);
  }

  .item .nome p {
    font-size: var(--f0);
  }

  .item .status {
    padding: 8px 12px;
  }

  .item .status h3 {
    font-size: var(--f0);
  }

  .item .links {
    flex-direction: column;
  }

  .item .links a.dominio {
    border-bottom: 2px solid var(--cor-cinza-3);
    border-right: none;
  }

  .item .links a {
    width: 100%;
    font-size: var(--f0);
    padding: 13px 5px;
  }
}
</style>
