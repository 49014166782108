<template>
  <section class="aviso">
    <div class="banner" :class="storePerfil.usuario?.assinatura?.plano">
      <div class="foto">
        <img v-if="storePerfil.usuario" :src="`https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/planos/${storePerfil.usuario?.assinatura?.plano}-ico.svg`" />
        <div class="fundo"></div>
      </div>
      <div class="texto">
        <h3>
          Você está no plano
          <span>{{ storePerfil.usuario?.assinatura?.plano }} {{ exibirPlano(storePerfil.usuario?.assinatura?.frequencia) }}</span>
        </h3>
        <p v-if="storePerfil.usuario?.assinatura?.plano !== 'free'">Próximo vencimento será dia {{ vencimento.dataFormatada || '' }} ({{ vencimento.diasRestantes || '' }} dias restantes)</p>
        <p v-else>Assine um dos planos Unic para liberar mais funcionalidades e tecnologias</p>
      </div>
      <Particles class="flat" :colorTheme="storePerfil.usuario?.assinatura?.plano" />
    </div>
  </section>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useStorePerfil } from '@stores'
import Particles from '@components/global/particles/Particles.vue'

const storePerfil = useStorePerfil()

const vencimento = ref({
  dataFormatada: '--/--/----',
  diasRestantes: 'N/A'
})

function exibirPlano(plano) {
  const valoresValidos = ['mensal', 'anual']
  return valoresValidos.includes(plano?.toLowerCase()) ? plano.toLowerCase() : ''
}

function formatarDataVencimento(dataVencimento) {
  if (!dataVencimento || isNaN(new Date(dataVencimento).getTime())) {
    return {
      dataFormatada: '--/--/----',
      diasRestantes: 'N/A'
    }
  }

  const data = new Date(dataVencimento)

  data.setDate(data.getDate() - 1)

  const dia = String(data.getDate()).padStart(2, '0')
  const mes = String(data.getMonth() + 1).padStart(2, '0')
  const ano = data.getFullYear()
  const dataFormatada = `${dia}/${mes}/${ano}`

  const hoje = new Date()
  const diferencaEmMilissegundos = data - hoje
  const diasRestantes = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24))

  return {
    dataFormatada,
    diasRestantes
  }
}

watch(
  () => storePerfil.usuario,
  (novoUsuario) => {
    if (novoUsuario?.assinatura?.dataVencimento) {
      vencimento.value = formatarDataVencimento(novoUsuario.assinatura.dataVencimento)
    }
  },
  { immediate: true }
)
</script>

<style scoped>
section.aviso {
  display: flex;
  width: 100%;
  padding: 20px 20px 0 20px;
}
.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-2);
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.banner .foto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0 15px 0 0;
  z-index: 3;
}
.banner .foto img {
  width: 20px;
  min-width: 20px;
  animation: zoom 2s ease-in-out infinite;
  filter: brightness(150%);
  z-index: 2;
}
.banner .foto .fundo {
  position: absolute;
  width: 100%;
  height: 100%;
  width: 70px;
  height: 70px;
  background: linear-gradient(20deg, var(--degrade));
  animation: girando 3s linear infinite;
  border-radius: 50%;
}
.banner.free .foto .fundo {
  background: linear-gradient(20deg, #21e664, #19c358, #12a94b, #0b8f3e, #057533);
}
.banner.essencial .foto .fundo {
  background: linear-gradient(20deg, #2b96ff, #0f64b8, #096acb, #063768, #2b96ff);
}
.banner.pro .foto .fundo {
  background: linear-gradient(20deg, #ff8820, #a5550f, #d36709, #6c3709, #ff8820);
}
.banner.expert .foto .fundo {
  background: linear-gradient(20deg, #ff395e, #8f0d25, #ab1a35, #9f0f29, #ff395e);
}
.banner.erro .foto .fundo {
  background: linear-gradient(20deg, #e63946, #cc2f3d, #b22634, #981c2b, #7e1222);
}
.banner.unlimited .foto .fundo {
  background: linear-gradient(20deg, #9039ff, #722fff, #5a26e6, #411fcc, #2b19b3);
}
.banner button {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  background: linear-gradient(20deg, var(--degrade));
  padding: 12px 22px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: 3;
}
.banner button:hover {
  filter: brightness(120%);
}
.banner .texto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 3;
}
.banner .texto h3 svg {
  width: 10px;
  min-width: 10px;
  margin: 0 5px;
}
.banner .texto h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}
.banner .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}
.banner .texto h3 span {
  text-transform: capitalize;
}
@media screen and (max-width: 1000px) {
  section.aviso {
    display: flex;
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  .banner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
  }
  .banner .foto {
    width: 65px;
    height: 65px;
    margin: 0 10px 0 0;
    border-radius: 20px;
  }
  .banner .foto svg {
    width: 35px;
    min-width: 35px;
  }
  .banner .foto .fundo {
    width: 120px;
    height: 120px;
  }
  .banner button {
    font-size: var(--f0);
    padding: 11px 18px;
    margin: 8px 0 0 0;
  }
  .banner button:hover {
    filter: brightness(120%);
  }
  .banner .texto {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .banner .texto h3 {
    font-size: var(--f2);
    line-height: 1.5;
    margin: 15px 0 0 0;
  }
  .banner .texto p {
    font-size: var(--f2);
    line-height: 1.5;
    margin: 5px 0 0 0;
    width: 100%;
    max-width: 340px;
  }
}
</style>
