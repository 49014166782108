<template>
  <section class="loading" :class="tela.layout">
    <div class="conteudo" :style="conteudoEstilo">
      <img :src="`${tela.imagemLogo || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/logo.svg'}`" />
      <p :style="tituloEstilo">{{ tela.textoTitulo || 'Esse é o título da sua tela de carregamento' }}</p>
      <span :style="loadingEstilo"></span>
    </div>
  </section>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStorePersonalizacao } from '@stores'

const storePersonalizacao = useStorePersonalizacao()

const tela = computed(() => storePersonalizacao.personalizacao?.telaLoading || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#FFFFFF',
  backgroundImage: `url('${tela.value.imagemFundo || ''}')`
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo || '#252939',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const loadingEstilo = computed(() => ({
  border: `3px solid ${tela.value.corLoading || '#262836'}`
}))

function atualizarFonte(fonte) {
  if (!fonte) return

  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

function renderizarFonte() {
  const fonte = storePersonalizacao.personalizacao?.telaLoading?.fonte
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte || 'Figtree',
    fontWeight: estilo || '400'
  }
}

watch(
  () => storePersonalizacao.personalizacao?.telaLoading?.fonte,
  (novaFonte, fonteAntiga) => {
    if (novaFonte !== fonteAntiga) {
      atualizarFonte(novaFonte)
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 100dvh;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  min-height: 100dvh;
  background-size: cover;
  background-position: center;
  z-index: 2;
}

.conteudo img {
  width: 100%;
  max-width: 150px;
  margin: 0 0 20px 0;
}

.conteudo p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 0 30px 0;
}

.conteudo span {
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  border-radius: 50%;
  border-top: 3px solid transparent !important;
  animation: girando 1s linear infinite;
}
</style>
