<template>
  <div id="aulas">
    <Loading :class="{ mostrar: state.verLoading }" />

    <ModalEditarAula />
    <ModalCriarAula />
    <ModalExcluirAula />
    <ModalDuplicarAula />
    <ModalPosicaoAulas />
    <ModalMigrarAula />

    <SectionTopo />
    <SectionAulas />
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, watch } from 'vue'
import { useStoreCursos, useStoreAreas, useStoreModulos, useStoreAulas } from '@stores'

import Loading from '@components/global/loading/LoadingArea.vue'
import ModalCriarAula from '@components/areaDeMembros/aulas/modals/CriarAula.vue'
import ModalExcluirAula from '@components/areaDeMembros/aulas/modals/ExcluirAula.vue'
import ModalEditarAula from '@components/areaDeMembros/aulas/modals/EditarAula.vue'
import ModalDuplicarAula from '@components/areaDeMembros/aulas/modals/DuplicarAula.vue'
import ModalPosicaoAulas from '@components/areaDeMembros/aulas/modals/EditarPosicaoAulas.vue'
import ModalMigrarAula from '@components/areaDeMembros/aulas/modals/MigrarAula.vue'

import SectionAulas from '@components/areaDeMembros/aulas/partials/SectionAulas.vue'
import SectionTopo from '@components/areaDeMembros/aulas/partials/SectionTopo.vue'

const storeAulas = useStoreAulas()
const storeModulos = useStoreModulos()
const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const emitter = inject('emitter')

const state = reactive({
  verLoading: true
})

async function carregarAulas() {
  state.verLoading = true

  const idArea = storeAreas.area?._id

  if (idArea) {
    await storeCursos.receberTodos(idArea)
  }

  const idCurso = storeCursos.curso?._id || storeCursos.cursos?.[0]?._id

  if (idCurso) {
    await storeCursos.receberPorId(idArea, idCurso)
    await storeModulos.receberTodos(idArea, idCurso)
  }

  const idModulo = storeModulos.modulo?._id || storeModulos.modulos?.[0]?._id

  if (idModulo) {
    await storeModulos.receberPorId(idArea, idCurso, idModulo)
    await storeAulas.receberTodos(idArea, idCurso, idModulo)
  }

  setTimeout(() => {
    state.verLoading = false
  }, 300)
}

watch(
  () => [storeAreas.area?._id],
  ([idArea]) => {
    if (idArea) {
      carregarAulas()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emitter.on('atualizarAulas', carregarAulas)
})
</script>

<style scoped>
#aulas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
