<template>
  <section class="menu" :class="tela.layout" :style="conteudoEstilo">
    <div class="titulo">
      <img :src="`${tela.imagemLogo || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/logo.svg'}`" />
      <p :style="tituloEstilo">{{ tela.textoTitulo || 'Esse é o título da sua tela de login' }}</p>
    </div>
    <div class="opcoes">
      <button :style="botoesEstilo">
        <Svgs :style="botoesSvgEstilo" nome="casa" />
        Início
      </button>
      <a target="_blank" :href="tela.linkBotaoExtraUm" v-if="tela.linkBotaoExtraUm" :style="botoesEstilo">
        <Svgs :style="botoesSvgEstilo" nome="link" />
        {{ tela.textoBotaoExtraUm || 'Botão Extra 1' }}
      </a>
      <a target="_blank" :href="tela.linkBotaoExtraDois" v-if="tela.linkBotaoExtraDois" :style="botoesEstilo">
        <Svgs :style="botoesSvgEstilo" nome="link" />
        {{ tela.textoBotaoExtraDois || 'Botão Extra 2' }}
      </a>
      <a target="_blank" :href="tela.linkBotaoExtraTres" v-if="tela.linkBotaoExtraTres" :style="botoesEstilo">
        <Svgs :style="botoesSvgEstilo" nome="link" />
        {{ tela.textoBotaoExtraTres || 'Botão Extra 3' }}
      </a>
      <button class="perfil">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
        <div class="nome">
          <h3 :style="botoesEstilo">Nome do Aluno</h3>
          <p :style="botoesEstilo">email@email.com</p>
        </div>
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStorePersonalizacao, useStorePerfil } from '@stores'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storePersonalizacao = useStorePersonalizacao()

const tela = computed(() => storePersonalizacao.personalizacao?.menu || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#E1E3EB'
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo || '#252939',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botoesEstilo = computed(() => ({
  color: tela.value.corBotoes || '#252939',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botoesSvgEstilo = computed(() => ({
  fill: tela.value.corIcones || '#252939'
}))

function atualizarFonte(fonte) {
  if (!fonte) return

  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

function renderizarFonte() {
  const fonte = storePersonalizacao.personalizacao?.menu?.fonte
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte || 'Figtree',
    fontWeight: estilo || '400'
  }
}

watch(
  () => storePersonalizacao.personalizacao?.menu?.fonte,
  (novaFonte, fonteAntiga) => {
    if (novaFonte !== fonteAntiga) {
      atualizarFonte(novaFonte)
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  background-color: #e1e3eb;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100dvh;
  padding: 30px;
  z-index: 6;
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titulo img {
  width: 100%;
  max-width: 130px;
  margin: 0 0 10px 0;
}

.titulo p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
  width: 100%;
}

.opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 20px 0 0 0;
}

.opcoes button {
  display: flex;
  align-items: center;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  background-color: transparent;
  padding: 10px 0;
  transition: all 0.3s;
}

.opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: #262836;
  margin: 0 10px 0 0;
}

.opcoes a {
  display: flex;
  align-items: center;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  background-color: transparent;
  padding: 10px 0;
  transition: all 0.3s;
}

.opcoes a svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: #262836;
  margin: 0 10px 0 0;
}

.opcoes a:hover {
  opacity: 0.7;
}

.opcoes button.perfil {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.opcoes button.perfil .foto {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #262836;
  background-size: cover;
  background-position: center;
}

.opcoes button.perfil .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 10px;
}

.opcoes button.perfil .nome h3 {
  font-size: var(--f3);
  font-family: var(--fonte-tela);
  color: #262836;
}

.opcoes button.perfil .nome p {
  font-size: var(--f2);
  font-family: var(--fonte-tela);
  color: #262836;
  margin: 5px 0 0 0;
}

@media screen and (max-width: 1000px) {
  section.login {
    flex-direction: column;
  }

  section.login.dois {
    padding: 60px 20px;
  }

  section.login.dois .conteudo {
    padding: 50px 30px;
  }

  section.login.tres {
    flex-direction: column;
    padding: 50px 20px;
  }

  section.login.tres .imagem {
    width: 100%;
  }

  section.login.tres .conteudo .titulo {
    width: 100%;
    padding: 50px 0 0 0;
  }

  section.login.tres .conteudo .campos {
    width: 100%;
    max-width: 100%;
  }

  section.login.tres .conteudo {
    flex-direction: column-reverse;
    min-height: auto;
    padding: 50px 30px;
  }

  .conteudo {
    width: 100%;
    padding: 30px;
  }
}
</style>
