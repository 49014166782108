<template>
  <Modal nome="editarAluno">
    <div class="perfil">
      <div class="foto" :style="`background-image: url('${state.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
      <div class="nome">
        <h3>{{ state.nome }}</h3>
        <p>{{ state.email }}</p>
      </div>
    </div>

    <div class="dados">
      <div class="linha">
        <h3>Whatsapp</h3>
        <Svgs nome="whatsapp" />
        <p>{{ state.whatsapp }}</p>
      </div>
      <div class="linha">
        <h3>Pontos</h3>
        <Svgs nome="dinheiro" />
        <p>{{ state.pontos }}</p>
      </div>
      <div class="linha">
        <h3>Último acesso</h3>
        <Svgs nome="sair" />
        <p>{{ state.ultimoAcesso }}</p>
      </div>
      <div class="linha">
        <h3>Registro</h3>
        <Svgs nome="relogio" />
        <p>{{ state.registro }}</p>
      </div>
    </div>

    <div class="dados" v-if="storeCursos.cursos?.length > 0">
      <h2>Cursos do aluno</h2>
      <div class="linha" v-for="curso in cursosMesclados" :key="curso._id">
        <div class="nome">
          <h3>{{ curso.nome }}</h3>
          <div class="info" v-if="curso.acessoLiberado">
            <Svgs nome="curso" />
            <p>Acesso até {{ formatarDataSimples(curso.dataVencimento) }}</p>
          </div>
          <div class="info bloqueado" v-if="!curso.acessoLiberado">
            <Svgs nome="cadeado" />
            <p>Acesso Bloqueado</p>
          </div>
        </div>
        <button class="editar" @click="abrirLiberarAcesso(curso._id)">
          <Svgs nome="editar" />
        </button>
        <button class="sair" @click="abrirBloquearAcesso(curso._id)" v-if="curso.acessoLiberado">
          <Svgs nome="sair" />
        </button>
        <div class="bloquear" :class="{ mostrar: state.bloquearAcesso && state.cursoId === curso._id }">
          <h3>Bloquear acesso?</h3>
          <button class="sim" @click="bloquearAcesso(curso._id)">Sim</button>
          <button class="nao" @click="state.bloquearAcesso = false">Não</button>
        </div>
        <div class="liberar" :class="{ mostrar: state.liberarAcesso && state.cursoId === curso._id }">
          <div class="info">
            <h3>Liberar Acesso até</h3>
            <input v-model="state.dataAcesso" type="tel" spellcheck="false" placeholder="00/00/0000" v-mask="`##/##/####`" />
          </div>
          <div class="botoes">
            <button class="sim" @click="liberarAcesso(curso._id)">Confirmar</button>
            <button class="nao" @click="state.liberarAcesso = false">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { computed, watch, reactive, inject, onMounted } from 'vue'
import { useStoreAreas, useStoreCursos, useStoreAlunos, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeAlunos = useStoreAlunos()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('editarAluno'))
const emitter = inject('emitter')

const state = reactive({
  nome: '',
  whatsapp: '',
  email: '',
  senha: '',
  imagemPerfil: '',
  pontos: '',
  ultimoAcesso: '',
  registro: '',
  cursosComprados: [],
  confirmacao: false,
  bloquearAcesso: false,
  liberarAcesso: false,
  dataAcesso: '',
  cursoId: null
})

const cursosMesclados = computed(() => {
  if (!storeCursos.cursos) {
    return []
  }
  const cursosCompradosMap = new Map(state.cursosComprados.map((curso) => [curso.idCurso._id, curso]))
  return storeCursos.cursos.map((curso) => {
    const cursoComprado = cursosCompradosMap.get(curso._id)
    if (cursoComprado) {
      return {
        ...curso,
        acessoLiberado: true,
        dataVencimento: cursoComprado.dataVencimento
      }
    } else {
      return {
        ...curso,
        acessoLiberado: false
      }
    }
  })
})

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}

function formatarDataSimples(data) {
  const dataSetada = new Date(data)

  const day = String(dataSetada.getDate()).padStart(2, '0')
  const month = String(dataSetada.getMonth() + 1).padStart(2, '0')
  const year = dataSetada.getFullYear()

  return `${day}/${month}/${year}`
}

function preencherDados() {
  state.nome = storeAlunos.aluno?.nome
  state.email = storeAlunos.aluno?.email
  state.whatsapp = storeAlunos.aluno?.contato?.whatsapp
  state.imagemPerfil = storeAlunos.aluno?.imagemPerfil
  state.pontos = storeAlunos.aluno?.pontos
  state.cursosComprados = storeAlunos.aluno?.cursosComprados
  state.ultimoAcesso = formatarData(storeAlunos.aluno?.dataUltimoAcesso)
  state.registro = formatarData(storeAlunos.aluno?.dataRegistro)

  const hoje = new Date()
  const dia = String(hoje.getDate()).padStart(2, '0')
  const mes = String(hoje.getMonth() + 1).padStart(2, '0')
  const ano = hoje.getFullYear()

  state.dataAcesso = `${dia}/${mes}/${ano}`
}

function limparCampos() {
  state.nome = ''
  state.email = ''
  state.whatsapp = ''
  state.imagemPerfil = ''
  state.pontos = ''
  state.ultimoAcesso = ''
  state.registro = ''
  state.cursoId = ''
}

async function pegarIdAluno() {
  const idArea = storeAreas.area?._id
  if (dadosModal.value.id) {
    await storeAlunos.receberPorId(idArea, dadosModal.value.id)
    preencherDados()
  }
}

async function liberarAcesso(idCurso) {
  const idArea = storeAreas.area?._id
  const idAluno = storeAlunos.aluno._id

  let [dia, mes, ano] = state.dataAcesso.split('/')
  let dataAcesso = new Date(`${ano}-${mes}-${dia}T00:00:00`)

  const hoje = new Date()
  hoje.setHours(0, 0, 0, 0)

  if (dataAcesso < hoje) {
    dataAcesso = hoje
  }

  dataAcesso.setDate(dataAcesso.getDate() + 1)
  dataAcesso.setHours(0, 0, 0, 0)

  const payload = {
    ativo: true,
    dataVencimento: dataAcesso.toISOString()
  }

  const resultado = await storeAlunos.alterarAcesso(idArea, idAluno, idCurso, payload)
  if (resultado) {
    state.liberarAcesso = false
    emitter.emit('atualizarCursosMesclados')
  }
}

async function bloquearAcesso(idCurso) {
  const idArea = storeAreas.area?._id
  const idAluno = storeAlunos.aluno._id

  const ontem = new Date()
  ontem.setDate(ontem.getDate() - 1)
  ontem.setHours(0, 0, 0, 0)

  const payload = {
    ativo: false,
    dataVencimento: ontem.toISOString()
  }

  const resultado = await storeAlunos.alterarAcesso(idArea, idAluno, idCurso, payload)
  if (resultado) {
    state.bloquearAcesso = false
    emitter.emit('atualizarCursosMesclados')
  }
}

function abrirLiberarAcesso(idCurso) {
  state.cursoId = idCurso
  state.liberarAcesso = true
}

function abrirBloquearAcesso(idCurso) {
  state.cursoId = idCurso
  state.bloquearAcesso = true
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIdAluno()
      }
    },
    { immediate: true }
  )

  emitter.on('atualizarCursosMesclados', pegarIdAluno)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

.perfil {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0 0 0;
}

.perfil .foto {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
}

.perfil .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}

.perfil .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.perfil .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
}

.cursos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--cor-cinza-3);
  margin: 20px 0 0 0;
}

.dados {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--cor-cinza-3);
  margin: 20px 0 0 0;
}

.dados h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 10px 0 20px 0;
  width: 100%;
}

.linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid var(--cor-cinza-3);
  position: relative;
}

.linha h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.linha p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-laranja);
  margin: 0 0 0 10px;
}
.linha svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-laranja);
  margin-left: auto;
}

.linha button.editar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  margin-left: auto;
  transition: all 0.3s;
}

.linha button.editar:hover {
  background-color: var(--cor-cinza-4);
}

.linha button.editar svg {
  fill: var(--cor-branco);
  margin: 0;
}

.linha button.sair svg {
  fill: var(--cor-branco-fixo);
  margin: 0;
}

.linha button.sair {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background-color: var(--cor-vermelho);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.linha button.sair:hover {
  background-color: var(--cor-vermelho-escuro);
}

.linha .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.linha .nome .info {
  display: flex;
  align-items: center;
  padding: 10px 0 0 0;
}

.linha .nome .info.bloqueado svg {
  fill: var(--cor-vermelho);
}

.linha .nome .info.bloqueado p {
  color: var(--cor-vermelho);
}

.linha .nome .info svg {
  fill: var(--cor-verde);
}

.linha .nome .info p {
  color: var(--cor-verde);
}

.linha .bloquear {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 150px;
  top: 0;
  right: 0;
  padding: 15px 0 15px 20px;
  background-color: var(--cor-cinza-2);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  transition: all 0.3s;
}

.linha .bloquear.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.linha .bloquear h3 {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: right;
}

.linha .bloquear button.sim {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-vermelho);
  background-color: transparent;
  padding: 0 5px;
  margin: 0 5px 0 10px;
  transition: all 0.3s;
}

.linha .bloquear button.nao {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: transparent;
  padding: 0 5px;
  transition: all 0.3s;
}

.linha .bloquear button:hover {
  opacity: 0.6;
}

.linha .liberar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--cor-cinza-2);
  padding: 10px 0 10px 20px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  transition: all 0.3s;
}

.linha .liberar.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.linha .liberar .info {
  display: flex;
  align-items: center;
}

.linha .liberar .botoes {
  display: flex;
  align-items: center;
  margin: 5px 0 0 0;
}

.linha .liberar h3 {
  margin: 0 5px 0 0;
}

.linha .liberar input {
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100px;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  border: none;
}

.linha .liberar input:focus {
  background-color: var(--cor-cinza-4);
}

.linha .liberar button.sim {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-verde);
  background-color: transparent;
  padding: 5px;
  margin: 0 10px 0 20px;
  transition: all 0.3s;
}

.linha .liberar button.nao {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: transparent;
  padding: 5px;
  transition: all 0.3s;
}

.linha .liberar button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1000px) {
}
</style>
